// @vue/component
import { filter } from "core-js/internals/array-iteration";
import Add from "@/views/Investments/add.vue";

export default {
    name: "Investments",

    components: { Add },

    mixins: [],

    props: {},

    data() {
        return {
            headers: [
                {
                    text: "المبلغ",
                    value: "amount",
                },
                {
                    text: "الملاحظات",
                    value: "notes",
                },
                {
                    text: "النوع",
                    value: "operationType",
                },
                {
                    text: "المستخدم",
                    value: "user",
                },
                {
                    text: "المستلم",
                    value: "receiverName",
                },
                {
                    text: "تاريخ العملية",
                    value: "transactionDate",
                },
                {
                    text: "تاريخ الاضافة",
                    value: "createdAt",
                },
                {
                    text: "",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
            show: false,
            loading: false,
            loadingUsers: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            length: 0,
            lengthUsers: 0,
            filter: {
                pageIndex: 1,
                pageSize: 15,
                search: null,
                count: 0,
                investorId: null,
            },
            filterUser: {
                pageIndex: 1,
                pageSize: 15,
                search: null,
                count: 0,
            },
            pageSizes: [5, 10, 15, 30, 50, 100],
            investments: [],
            users: [],
        };
    },

    computed: {},

    watch: {
        "filterUser.search": {
            handler: "handleSearchChange",
            deep: true,
        },
    },

    async created() {
        await this.getInvestments(
            this.filterUser.pageIndex,
            this.filter.pageSize,
            this.filter.search,
            this.filter.investorId
        );
    },

    methods: {
        FormattedDate(dateStr) {
            const date = new Date(dateStr); // Your date object
            const formattedDate = new Intl.DateTimeFormat("en-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false, // Use 24-hour format
            }).format(date);
            return formattedDate;
        },
        CleanFilterInvestor() {
            this.filterUser.search = null;
            this.filterUser.count = 0;
            this.filterUser.pageSize = 15;
            this.filterUser.pageIndex = 1;
            this.users = [];
        },
        async handleSearchChange(newSearch, oldSearch) {
            // This function will be called whenever the 'search' property changes
            // console.log(`Search changed from ${oldSearch} to ${newSearch}`);
            // Introduce a delay of 1 second (1000 milliseconds) using setTimeout
            setTimeout(async () => {
                await this.getUsers(
                    this.filterUser.pageIndex,
                    this.filterUser.pageSize,
                    newSearch
                );
                // Your logic here (e.g., make an API call or update component state)
                // console.log('Delayed action after 1.1 second');
            }, 1100);
        },
        async getUsers(pageIndex, pageSize, search) {
            this.loadingUsers = true;
            this.users = [];
            try {
                let queryUsers = `Users?IsDelete=false`;
                if (pageIndex !== undefined && pageIndex !== null) {
                    queryUsers = queryUsers + `&PageIndex=${pageIndex}`;
                }
                if (pageSize !== undefined && pageSize !== null) {
                    queryUsers = queryUsers + `&PageSize=${pageSize}`;
                }
                if (search !== undefined && search !== null) {
                    queryUsers += `&Search=${search}`;
                }
                const res = await this.$http.get(queryUsers);
                // console.log(res.data.result.data);
                this.users = res.data.result.data;
                this.filterUser.count = res.data.result.count;
                this.lengthUsers =
                    res.data.result.count === undefined
                        ? 0
                        : Math.ceil(
                              res.data.result.count / this.filterUser.pageSize
                          );
            } catch (err) {
                console.log(err);
                // service.faild(err.response.data.message);
            } finally {
                this.loadingUsers = false;
            }
            // console.log(this.users,this.filterUser);
        },
        async getInvestments(pageIndex, pageSize, search, investorId) {
            // console.log("Loading Invest")
            this.investments = [];
            this.loading = true;
            let query = `/investments/Investments?IsDelete=false`;
            if (pageIndex !== undefined && pageIndex !== null) {
                query = query + `&PageIndex=${pageIndex}`;
            }
            if (pageSize !== undefined && pageSize !== null) {
                query = query + `&PageSize=${pageSize}`;
            }
            if (search !== undefined && search !== null) {
                query += `&Search=${search}`;
            }
            if (investorId !== undefined && investorId !== null) {
                query = query + `&InvestorId=${investorId}`;
            }
            await this.$http
                .get(query)
                .then((response) => {
                    // console.log(response.data.result.count);
                    const invests = response.data.result.data;
                    if (Array.isArray(invests)) {
                        invests.forEach((invest) => {
                            this.investments.push(invest);
                        });
                        this.filter.count = response.data.result.count;
                        this.length =
                            response.data.result.count === undefined
                                ? 0
                                : Math.ceil(
                                      response.data.result.count /
                                          this.filter.pageSize
                                  );
                        // console.log(`
                        // is undefined : ${response.data.result.count === undefined} \n
                        // حجم صفحة : ${ Math.ceil(response.data.result.count / this.filter.pageSize)} \n
                        // size: ${ this.filter.pageSize} \n
                        //   count: ${ response.data.result.count} \n
                        // `);
                    }
                })
                .catch((error) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${error.response.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async deleteItem(id, index) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$http
                        .delete(`Investments?id=` + id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.investments.splice(index, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.response.data.message}`,
                                type: "error",
                            });
                        })
                        .finally(() => (this.loading = false));
                }
            });
        },
        async resetFilter() {
            this.$refs.filterForm.reset();
            await this.getInvestments(
                this.filterUser.pageIndex,
                this.filter.pageSize,
                this.filter.search,
                this.filter.investorId
            );
        },
        GetType(type) {
            console.log(type);
            if (type === 1) {
                return "إيداع";
            } else if (type === 2) {
                return "سحب";
            } else {
                return "أرباح";
            }
        },
    },
};
