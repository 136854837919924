import { render, staticRenderFns } from "./Investments.vue?vue&type=template&id=e6b1b186&scoped=true"
import script from "./_Investments.js?vue&type=script&lang=js&external"
export * from "./_Investments.js?vue&type=script&lang=js&external"
import style0 from "./_Investments.css?vue&type=style&index=0&id=e6b1b186&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6b1b186",
  null
  
)

export default component.exports